import { View, Text } from 'react'
import React from 'react'

const InfoSection = (props) => {
  return (
    <div>
 <div className="text-slate-600 mx-auto grid  grid-cols-2 gap-y-4 px-4 py-1 sm:my-10 sm:rounded-md sm:border sm:shadow">
  <div className="col-span-2 col-start-1 flex flex-col justify-between border-b py-3 sm:flex-row">
    <p className="font-medium">Requests Overview</p>
    {/* <select className="text-slate-500 hover:bg-slate-200 rounded-lg border-2 px-4 py-2 font-medium focus:outline-none focus:ring">
      <option value="last-month">Last Month</option>
      <option value="last-month">Last 2 Months</option>
      <option value="last-month">This Year</option>
    </select> */}
  </div>
  <div className="col-span-2 -mx-4 bg-gradient-to-t from-indigo-500 to-blue-500 px-4 py-8 sm:col-span-1 sm:mx-0 sm:rounded-xl sm:py-4">
    <p className="mb-4 font-medium text-indigo-100">Total Requests Made in pipeline</p>
    <div className="mb-6 flex max-w-xs">
      <div className="mb-3 flex h-12 w-12 items-center justify-center rounded-2xl bg-indigo-100 text-indigo-400 sm:mr-3 sm:mb-0">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      </div>
      <div className="px-4">
        <p className="mb-1 text-2xl font-black text-white">{props.data?.totalRequests} REQUESTS </p>
        <p className="font-medium text-indigo-100">UGX {props && props.data && props.data.totalRidexEarning ? props.data?.totalRidexEarning: "" } RIDEX EARNINGS</p>
      </div>
    </div>
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-col items-center px-4 py-1">
        <p className="text-lg font-medium text-white">{props.data?.gasServiceRequests}</p>
        <p className="text-xs font-medium text-indigo-100">GAS SERVICE</p>
      </div>
      <div className="mb-1 flex flex-col items-center px-4 py-1 sm:mr-1 sm:mb-0">
        <p className="text-lg font-medium text-white">{props.data?.repairServiceRequests}</p>
        <p className="text-xs font-medium text-indigo-100">REPAIR SERVICE</p>
      </div>
      {/* <div className="mb-1 flex flex-col items-center rounded-2xl bg-white px-4 py-1 sm:mr-1 sm:mb-0">
        <p className="text-lg font-medium text-indigo-500">21</p>
        <p className="text-xs font-medium text-indigo-500">Refunds</p>
      </div> */}
      <div className="flex flex-col items-center px-4 py-1">
        <p className="text-lg font-medium text-white">{props.data?.repairServiceRequests}</p>
        <p className="text-xs font-medium text-indigo-100">ROADSIDE SUPPORT</p>
      </div>
    </div>
  </div>
  <div className="col-span-2 grid grid-cols-2 gap-4 py-4 sm:col-span-1 sm:gap-8 sm:px-4">
    <div className="">
      <p className="text-lg font-bold">{props.data?.completedRequests} Requests</p>
      <p className="text-slate-400 mb-2 font-medium">{props.data?.completedRequestsEarnings}</p>
      <span className="rounded-full bg-green-200 px-2 py-0.5 text-xs font-medium text-green-600">Completed</span>
    </div>
    <div className="">
      <p className="text-lg font-bold">{props.data?.totalPendingApproval} Requests</p>
      {/* <p className="text-slate-400 mb-2 font-medium">$230,000</p> */}
            {/* ToDo: needs changing we need to calculate the requests pending approval  */}
      <span className="rounded-full bg-yellow-200 px-2 py-0.5 text-xs font-medium text-yellow-600">Pending Approval</span>
    </div>
    <div className="">
      <p className="text-lg font-bold">{props.data?.cancelledRequests} Requests</p>
      {/* <p className="text-slate-400 mb-2 font-medium">$230,000</p> */}
      <span className="rounded-full bg-red-200 px-2 py-0.5 text-xs font-medium text-red-600">Cancelled By Provider</span>
    </div>
    <div className="">
      <p className="text-lg font-bold">{props.data?.cancelledRequests} Requests</p>
      {/* <p className="text-slate-400 mb-2 font-medium">$230,000</p> */}
      <span className="rounded-full bg-red-200 px-2 py-0.5 text-xs font-medium text-red-600">Cancelled By Customer</span>
    </div>
  </div>
  <div className="col-span-2 col-start-1 grid grid-cols-2 gap-6 border-t py-4 sm:grid-cols-2 sm:px-4 sm:py-8">
    <div className="">
      {/* <p className="text-slate-500 text-sm">Estimated Revenue</p>
      <p className="text-xl font-medium">$924,883</p> */}
      {/* <span className="rounded-full py-0.5 text-xs font-medium">Download Pdf</span>
      <div className="mb-3 flex h-12 w-12 items-center justify-center rounded-2xl bg-indigo-100 text-indigo-400 sm:mr-3 sm:mb-0">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
    </div> */}
    </div>
    <div className="">
      {/* <p className="text-slate-500 text-sm">Estimated Profit</p>
      <p className="text-xl font-medium">$213,002</p> */}
      {/* <span className="rounded-full py-0.5 text-xs font-medium">Download Pdf</span>
      <div className="mb-3 flex h-12 w-12 items-center justify-center rounded-2xl bg-indigo-100 text-indigo-400 sm:mr-3 sm:mb-0">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      </div> */}
  </div>
    </div>
</div>

    </div>
  )
}

export default InfoSection