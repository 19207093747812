import React, { useEffect, useState } from 'react'
import {APIProvider, Map, Marker, useMarkerRef, AdvancedMarker } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { Container, Button } from 'react-floating-action-button';
import DropdownComponent from '../../../components/FormInput/DropdownComponent';
import { getToken, getUser } from '../../../utils/HelperFunction';
// import { GoogleMap, InfoBox, InfoBoxF, InfoWindow, InfoWindowF, Marker, useJsApiLoader } from '@react-google-maps/api';

export default function () {
    const [ markerRef ] = useMarkerRef();
    const [ providers, setProviders ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ showModal, setShowModal ] = useState(true);
    const [ latitude, setLatitude ] = useState(null);
    const [ longitude, setLongitude ] = useState(null);
    const [ token, setToken ] = useState({token: JSON.stringify(getToken())})
    const [ gasname, setGasName ] = useState('');
    const [ gastype, setGasType ] = useState('');
    const [ gassize, setGasSize ] = useState('');
    const [ gasdelivery, setGasDelivery ] = useState('');
    const [ accessorytype,setAccessoryType ]=useState('');
    const [infoModal, setInfoModal] = useState(false)
    const customerId = getUser().user_id;
    const [ requestDetails, setRequestDetails ] = useState({})
    const [ requestId, setRequestId ] = useState(null)
    const token_tk = getToken();
    const [responseMessage, setResponseMessage]= useState(false)  
    const [successMessage, setSuccessMessage] = useState(true)
    const [requestResponseMessage, setRequestResponseMessage] = useState('');
    const [pageLoader, setPageLoader] = useState(false);

    async function getProviders(){
        setLoading(true)
        await axios.get('https://myridex.tech/api/v1/authentication/user/providers')
        .then(res=> {
            setProviders(res.data.data)
        })        
        .catch(err=> {
             console.log('Coordinates error:',err)
         })
        .finally(()=>{
            setLoading(false)
        })
    }

    function searchGasProvider(){
        if(!gasname){
          setRequestResponseMessage('Please Select A Gas Name')
          setResponseMessage(true)
          setSuccessMessage(false)

          return ''
        }
        if(!gastype){
          setRequestResponseMessage('Please Select A Gas Type')
          setResponseMessage(true)
          setSuccessMessage(false)

          return ''
        }
        if(!gassize){
          setRequestResponseMessage('Please Select A Gas Size')
          setResponseMessage(true)
          setSuccessMessage(false)

          return ''
        }
        if(!latitude || !latitude){
          setRequestResponseMessage('Please Enable Location Permission on Your Browser')
          setResponseMessage(true)
          setSuccessMessage(false)

          return ''
        }
        let postData = {
          gasType: gastype,
          longitude:longitude,
          latitude:latitude,
          request: 'GAS',
          gasName: gasname,
          gasSize: gassize,
          gasDelivery: gasdelivery,
          accessoryType: accessorytype,
          token: token_tk,
          customerId: customerId,
          type: "ALL",

          gasProviderInfo: {
            gasName: gasname,
            gasType: gastype,
            gasSize: gassize,
            accessoryType:accessorytype
        }
        }
        console.log('data',postData)

        setPageLoader(true)
        setShowModal(false)
        //remove the modal that shows the requests ---> 
        getProvidersToRequest(postData?.gasName, postData?.gasType, postData?.gasSize, postData?.customerId)
        // navigation.navigate("CustomerMaps", { payload: postData});
        // setPageLoader(true)
      }

      const gasNames = [
        { value: 'Total', label: 'Total Energies'},
        { value: 'Shell', label: 'Shell'},
        { value: 'Rubis', label: 'Rubis'},
        { value: 'MoGas', label: 'MoGas'},
        { value: 'KGas', label: 'KGas'},
        { value: 'Ramco', label: 'Ramco'},
        { value: 'Stabex', label: 'Stabex' },
        { value: 'Hass', label: 'Hass' },
        { value: 'Hashi', label: 'Hashi' },
        { value: 'Dc', label: 'Dc' },
        { value: 'Imperial', label: 'Imperial' },
        { value: 'Easy', label: 'Easy' },
        { value: 'Taifah', label: 'Taifah' },
        { value: 'Save Tree', label: 'Save Tree' },
        { value: 'Orxy', label: 'Orxy' },
        { value: 'Energy', label: 'Energy' },
        { value: 'Ola', label: 'Ola' },
        { value: 'Amex', label: 'Amex' },
        { value: 'Safe', label: 'Safe' },
        { value: 'Lake', label: 'Lake' },
        { value: 'Ugas', label: 'Ugas' },
        { value: 'Pet', label: 'Pet' }
    ];
    
    let gasTypes = [
        { value: "Fullset", label: "Full Set"},
        { value: "Refill", label: "Refill" },
    ]
    
    let accessoryTypes = [
        { value: "regulator", label: "Regulator" }, 
        { value: "hose", label: "Gas Hose" },
        { value: "regulator and hose", label: "Both" },
    ]
    
    let gasSize = [
      {value:"3", label:"3kg"},
      {value:"6", label:"6kg"},
      {value:"10", label:"10kg"},
      {value:"12", label:"12kg"},
      {value:"13", label:"13kg"},
      {value:"15", label:"15kg"},
      {value:"24", label:"24kg"},
      {value:"36", label:"36kg"},
      {value:"45", label:"45kg"},
      {value:"48", label:"48kg"},
    ]
    
    // <Picker.Item label="Yes" value="delivery" />
    // <Picker.Item label="No" value="collected" />
    let deliveryOptions = [
        { value: 'delivery', label: 'Yes' },
        { value: 'collected', label: 'No'}
    ]

    function getLocation(){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                console.log(position)
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
              },
              (error) => {
                console.error(error);
              }
            );
          } else {
            console.error('Geolocation is not supported by this browser.');
          }
    }
  
   

    async function getProvidersToRequest(g_name, g_type, g_size ,g_customer_id){
        // await setReason(payload?.selectedProblemCategory || 'Others')  
        // console.log('Here we are',payload);
        
        let data = JSON.stringify({
            "latitude": latitude,
            "longitude": longitude,
            // "customerId": payload?.customerId,
            "type": 'ALL',
            gasProviderInfo: {
                gasName: g_name,
                gasType: g_type,
                gasSize: g_size
            }
        });
        // console.log(data);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://myridex.tech/api-v1/requests/providers/-/serviceTypes/GAS`,
            headers: { 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer `+ token_tk          
            },
            data : data
        };

        // if(payload.token){
        //     await axios.request(config)
        //     .then((response) => {
        //         console.log('Response data:',JSON.stringify(response.data.providers));
        //   setProviders(response.data.providers)
        //   setRequestId(response.data.requestId)
        //   setShowModal(false)
        //     })
        //     .catch(error=>{
        //         console.log(error.message)
        //         Alert.alert('No Providers Found', 'The providers you are searching for are not currently present in your current location',)
        //     })
        // }else{
        //     Alert.alert('Failed', 'Still loading..')
        // }


        // axios.post('http://127.0.0.1:8000/api/v1/authentication/user/admin_dashboard/api_call_requests', { 
        axios.post('https://myridex.tech/api-v1/requests/providers/-/serviceTypes/GAS', { 

          "latitude": latitude,
          "longitude": longitude,
        // "customerId": payload?.customerId,
          "customerId": g_customer_id,
          "type": 'ALL',
        "gasProviderInfo": {
            gasName: g_name,
            gasType: g_type,
            gasSize: g_size
        }},
        {
        headers: {
          'Authorization': `Bearer `+token_tk ,
          'Content-Type': 'application/json' // Adjust content type as needed
        }
          })
        .then(response => {
          // Handle success
          console.log('Response data:',JSON.stringify(response.data.providers));
          setProviders(response.data.providers)
          setRequestId(response.data.requestId)
          // setShowModal(false)
          setPageLoader(false)
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
          setRequestResponseMessage("No Providers Found.")
          setProviders()
          // setShowModal(false)
          setResponseMessage(true)
          setSuccessMessage(false)
          // setShowModal(false)
          setPageLoader(false)
        });

    }

    useEffect(()=>{
      // ToDO: fix the user_id in the customer_id column, 
        getLocation()
        // getProviders()
        // console.log("THE GET USER "+JSON.stringify(getUser().user_id))
    },[])

    const getSingleProvider = (values)=>{
      // console.log("getting a single provider"+JSON.stringify(values))
      setRequestDetails(values)
      // requestDetails
      //call the modal now 
      setInfoModal(true);
      //fix the button to exit the modal 
    }
    async function placeRequestToProvider(gas_new_id, receiver_id){

      // console.log("the passed gas id is "+receiver_id)
        // return '';
        let new_gas_type; // Declare new_gas_type outside of the if-else block

      if (gastype === "Fullset") {
          new_gas_type = "fullSetPrice";
      } else {
          new_gas_type = "refillPrice";
      }

      let data = JSON.stringify({
          "customerId": customerId,
          "serviceType": "GAS",
          "latitude": latitude,
          "longitude": longitude,
          "searchRequestId": requestId,
          "gasId": gas_new_id,
          "gasType": new_gas_type,
      });
        // Alert.alert("data",data)
        // console.log("THE DATA BEING SENT IS "+data +'AND THE TOKEN IS '+JSON.stringify(token_tk))
        console.log("THE DATA BEING SENT IS "+requestDetails.id)

        // return ('');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://myridex.tech/api-v1/requests/requests/-/providers/${requestDetails.id}`,
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer `+ token_tk 
        },
            data : data
        };

      //   let config2 = {
      //     method: 'post',
      //     maxBodyLength: Infinity,
      //     url: `http://127.0.0.1:8000/api/v1/authentication/user/admin_dashboard/api_call_get_gas`,
      //     headers: { 
      //         'Content-Type': 'application/json', 
      //         'Authorization': `Bearer `+ token_tk 
      // },
      //     data : data
      // };

        axios.request(config)
            .then((response) => {
            console.log(JSON.stringify(response.data));

            setRequestResponseMessage("Request made Successfully ")
            setResponseMessage(true)
            setSuccessMessage(true)
            sendNotification(receiver_id)

            // if(response.d)

            // if respons eis successful, lets return a model else stating the response has been sent to the provider 
          // if(response.data.){

          // }else {

          // }
            //Show Model Saying Request sent successfully, then show a notification 

            // bottomSheetRef?.current.close()
            // Alert.alert('Success', 'Request Placed. Wait for your confirmation')
            // sendNotify(requestDetails.id);
        })
        .catch((error) => {
            console.log(error.message);
            setRequestResponseMessage("Request Failed. "+error.message)
            setResponseMessage(true)
            setSuccessMessage(false)

        });
 
    }
    const sendNotification = async (receiver_id)=>{
      // https://myridex.tech/api/v1/authentication/user/fcm_manager/send-user-fcm-token
      let data = JSON.stringify({
        "messageTitle":"Gas Request",
        "messageBody":"Please Check your Requests ", 
        "userId":receiver_id
    });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://myridex.tech/api/v1/authentication/user/fcm_manager/send-user-fcm-token`,
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer `+ token_tk 
    },
        data : data
    };

    axios.request(config)
    .then((response) => {
    console.log(JSON.stringify(response.data));
})
.catch((error) => {
    console.log(error.message);
});
 }






    return (
        <div>
            <div className="relative z-10 pt-[100px] md:pt-[100px] lg:pt-[100px] pb-[100px] bg-primary1 overflow-hidden" >
                <div className="container">
                    <div className="flex flex-wrap items-center -mx-4">
                        <div className="w-full px-4">
                            <div className="text-center">
                            <h1 className="font-semibold text-white text-4xl">Make a request</h1>
                           
                            </div>
                        </div>
                    </div>
                </div>            
            </div>

            <section className="bg-white py-4 lg:py-0">
                <div className="container-fluid">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full px-0">
                            {
                                providers && !loading && latitude !== null &&

                                <APIProvider apiKey={'AIzaSyCcBdz1dGf3g3AlW1LgAu9xFImR-_z1uMU'}>
                                    <Map
                                     
                                        style={{width: '100%', height: '100vh'}}
                                        defaultCenter={{lat: latitude , lng: longitude }}
                                        defaultZoom={14}
                                        gestureHandling={'greedy'}
                                        disableDefaultUI={true}
                                        mapId={'12344'}
                                    >
                                       <AdvancedMarker
                                        position={{ lat: latitude, lng: longitude }}
                                        onClick={() => { console.log("Marker clicked") }}
                                      >
                                        <div style={{ position: 'relative', width: '150px', height: '150px', borderRadius: '50%', border: '2px solid red', backgroundColor: 'rgba(255,0,0,0.2)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <div style={{ backgroundColor: 'red', color: 'white', padding: '5px', position: 'absolute', top: '-35px', left: '-35px' }}>
                                            <h3>MY LOCATION</h3>
                                          </div>
                                          <img src={require("../../../assets/img/person.png")} alt="User Icon" style={{ width: '35px', height: '35px' }} />
                                        </div>
                                      </AdvancedMarker>

                                      {providers.map((provider, index) => (
                                        <AdvancedMarker
                                          key={index}
                                          position={{ lat: parseFloat(provider.latitude), lng: parseFloat(provider.longitude) }}
                                          onClick={ () => {getSingleProvider(provider) }}
                                        >
                                          <div style={{ backgroundColor: 'black', color: 'white', padding: '5px' }}>
                                            <h2>UGX. {gastype==="Fullset"? Math.round(provider?.fullSetAmount).toLocaleString("en-US"): Math.round(provider?.refillAmount).toLocaleString("en-US")}</h2>
                                          </div>
                                          <img src={require("../../../assets/img/profille.png")} alt="User Icon" style={{ width: '25px', height: '25px' }} />
                                        </AdvancedMarker>
                                      ))}     
                                    </Map>
                                </APIProvider>
                            }
                            
                        </div>
                    </div>
                </div>
                <Container>
                <Button
                    tooltip="Make Request"
                    icon="fas fa-plus"
                    styles={{
                        backgroundColor: "#0088cc",
                        color: "#fff",
                        fontSize: "12px", //
                        // padding: "10px 20px" // 
                    }}
                    onClick={() => setShowModal(true)}
                >
                    Make Request
                </Button>
                </Container>
            </section>

            <>
      
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Make Request
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    Please fill in the form below to make a request.
                  </p>
                  <form className="w-full">
                    
                    <DropdownComponent
                        label="Gas Name"
                        placeholder="Select gas name"
                        options={gasNames}
                        value={gasname}
                        onChange={(e) => setGasName(e.value)}
                    />
                    <DropdownComponent
                        label="Gas Type"
                        options={gasTypes}
                        placeholder="Select gas type"
                        value={gastype}
                        onChange={(e) => setGasType(e.value)}
                    />
                    <DropdownComponent
                        label="Accessory Type"
                        options={accessoryTypes}
                        placeholder="Select accessory type"
                        value={accessorytype}
                        onChange={(e) => setAccessoryType(e.value)}
                    />
                    <DropdownComponent
                        label="Gas Size"
                        options={gasSize}
                        placeholder="Select gas size"
                        value={gassize}
                        onChange={(e) => setGasSize(e.value)}
                    />
                    <DropdownComponent
                        label="Delivery Option"
                        options={deliveryOptions}
                        placeholder="Select delivery option"
                        value={gasdelivery}
                        onChange={(e) => setGasDelivery(e.value)}
                    />
                    
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 primary1"
                    type="button"
                    onClick={searchGasProvider}
                  >
                    Find Gas Provider
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>

    {infoModal && (
    <div className="relative">
        <div
            className="justify-end items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setInfoModal(false)} // Close the modal when clicking anywhere inside it
        >
            <div className="relative m-2 my-8 max-w-sm rounded-lg border border-gray-100 bg-gray-100 px-12 py-6 shadow-md">
                <button
                    className="absolute top-0 right-0 p-4 text-red-500"
                    onClick={() => setInfoModal(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-5 w-4"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div className="mx-auto my-8 flex max-w-xs flex-col items-center rounded-xl border px-4 py-4 text-center md:max-w-lg md:flex-row md:items-start md:text-left">
                    <div className=" m-3">
                        <p className="text-lg font-medium text-gray-700">{requestDetails?.firstName?.toUpperCase()}{' '}{requestDetails?.lastName?.toUpperCase()}</p>
                        <p className="mb-2 text-sm font-bold text-green-600 ">Price est: {gastype==="Fullset"? Math.round(requestDetails?.fullSetAmount).toLocaleString("en-US"): Math.round(requestDetails?.refillAmount).toLocaleString("en-US")} UGX</p>
                        <div className="flex space-x-5">
                        {/* {gastype=="Fullset"? Math.round(requestDetails?.fullSetAmount).toLocaleString("en-US"): Math.round(requestDetails?.refillAmount).toLocaleString("en-US")} */}
                            <div className="flex flex-col items-center rounded-xl bg-gray-100 px-3 py-1">
                                <p className="text-xs font-medium text-gray-500">Time</p>
                                <p className="text-lg font-medium text-gray-600">{requestDetails?.estimatedArrivingTime}</p>
                            </div>
                            <div className="flex flex-col items-center rounded-xl bg-gray-100 px-3 py-1">
                                <p className="text-xs font-medium text-gray-500">{''}</p>
                                <p className="text-lg font-medium text-gray-600">{''}</p>
                            </div>
                            <div className="flex flex-col items-center rounded-xl bg-gray-100 px-3 py-1">
                                <p className="text-xs font-medium text-gray-500">Distance</p>
                                <p className="text-lg font-medium text-gray-600">{Math.round(requestDetails?.distanceFromUser / 1000)} Km</p>
                            </div>
                        </div>
                        <div className="my-3"></div>
                        <div className="flex space-x-3">
                            {/* <button className="w-full rounded-lg border-2 bg-white px-3 py-1 font-medium text-gray-500 text-sm">Message</button> */}
                            <button className="w-full rounded-lg border-2 border-transparent bg-blue-600 px-3 py-1 font-medium text-white text-sm" onClick={() => { placeRequestToProvider(requestDetails?.gasId, requestDetails?.id) }}>Request</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="fixed inset-0 bg-black opacity-10" onClick={() => setInfoModal(false)}></div>
    </div>
)}
    {responseMessage &&(
    <div className=' z-50 flex items-center justify-center fixed inset-0' >
    <div class="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-12 py-6 shadow-md">
      <button class="absolute top-0 right-0 p-4 text-gray-400" onClick={()=>{setResponseMessage(false)}}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-5 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <p class="relative mb-1 text-sm font-medium">
        {successMessage===true?  <span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </span>:<span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
          </span>}
        {successMessage===true?<span class="text-gray-50">Success!</span>: <span class="text-gray-50">Error!</span> }
      </p>
      
      <p class="text-sm text-gray-200">{requestResponseMessage}</p>

    </div>
    </div>
      )}

      { pageLoader && (
      <div>
        <div className=' z-50 flex items-center justify-center fixed inset-0'>
            <button class="flex items-center gap-x-4 rounded-xl px-8 py-3 font-medium text-white" style={{backgroundColor: '#36B6FF'}} >
              <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Loading...</span>
            </button>
        </div>
      </div>
)}

    </div>
    
  )
} 

const containerStyle = {
    width: '400px',
    height: '400px'
  };
