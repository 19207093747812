import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer  } from 'recharts';
import axios from 'axios';
import { getToken} from "../../../utils/HelperFunction";
const TransactionChart = () => {
    const accessToken= getToken(); 
    // const [data, setData] = useState(''); 
    const fetchData = async () => {
        try {
          // Make GET request with authorization header
          const response = await axios.get('https://myridex.tech/api/v1/authentication/user/admin_dashboard/getMonthlyData', {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Add authorization header with token
            },
          });
    
          if (response && response.data ) {
            console.log(response.data)
            setMonthlyData(response.data);
          } else {
            console.error('Response data is missing:', response);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      useEffect(()=>{
        fetchData()
      },[]); 
    const [monthlyData, setMonthlyData] = useState([]);

  return (
    <div>

<ResponsiveContainer width="100%" height={400}>
            <LineChart data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month_year" />
                <YAxis fontSize={12} fontWeight={"bold"} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="total_amount" stroke="green" strokeWidth={3} />
            </LineChart>
        </ResponsiveContainer>
    </div>
  )
}

export default TransactionChart