import React from 'react'
import { useRoutes } from 'react-router-dom'
import HomePage from '../pages/website/HomePage'
import ServicesPage from '../pages/website/ServicesPage'
import ContactPage from '../pages/website/ContactPage'
import AuthPage from '../pages/auth/AuthPage'
import RequestPage from '../pages/website/WebParts/RequestPage'
import AboutPage from '../pages/website/AboutPage'
import NotFoundPage from '../pages/website/Layout/NotFoundPage'
import UserAccount from '../pages/website/User/UserAccount'
import protectedRoute from '../utils/protectedRoute'

export default function Root() {
  const element = useRoutes([
    {
      path: '/',
      element: <HomePage/>
    },
    {
      path:'services',
      element: <ServicesPage/>
    },
    {
      path: 'about',
      element: <AboutPage/>
    },
    {
      path: 'contact',
      element: <ContactPage/>
    },
    {
      path: 'auth',
      element: protectedRoute() ? <HomePage/> : <AuthPage/>
    },
    {
      path: 'request',
      element: protectedRoute() ? <RequestPage/> : <AuthPage/>
    },
    {
      path: '*',
      element: <NotFoundPage/>
    },
    {
      path: 'account',
      element: protectedRoute() ? <UserAccount/> : <AuthPage/>
    }
    
  ])
  return element
}
