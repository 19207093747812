import React from 'react'
import { getUser } from './HelperFunction'

export default function protectedRoute() {
  let user = getUser();

  if (!user) {
    return false
  }else{
    return true;
  }

}
