import React, { useEffect, useState } from 'react'
import Accounts from './Accounts'
import Notifications from './Notifications'
import Metrics from './dashboardPage/Metrics'
import Maps from './Maps'
import Payments from './Payments'
import api, { baseUrl } from "../../services/api";
import { getToken} from "../../utils/HelperFunction";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { setDashboardData, selectDashboardData } from '../../store/dashboardDataSlice';
// import { setDashboardData } from '../../store/dashboardDataSlice';


export default function Dashboard() {
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectDashboardData);
  
  const accessToken= getToken(); 
  const initialState = {
    status: 0,
    success: false,
    total_user: '',
    total_customers: '',
    total_providers: '',
    totalRequests: '',
    completedRequests: '',
    completedRequestsEarnings: '',
    rejectedRequests: '',
    cancelledRequests: '',
    gasServiceRequests: '',
    repairServiceRequests: '',
    totalProviderAmount: '',
    totalRidexEarning: '',
    totalPendingApproval:'',
    totalRiders:''
  };
  const [data, setData] = useState(initialState); 
  const fetchData = async () => {
    try {
      // Make GET request with authorization header
      const response = await axios.get('https://myridex.tech/api/v1/authentication/user/admin_dashboard/user_metrics', {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Add authorization header with token
        },
      });

      // Set data state with response data
      setData(response.data);
      // console.log(response.data)
      if (response && response.data) {
        console.log(response.data)
        dispatch(setDashboardData(response.data));

      } else {
        console.error('Response data is missing:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(()=>{
    fetchData()
  },[]); 


  const [ open, setOpen ] = useState(true)
  const [ view, setView ] = useState('')
  const [loading, setLoading] = useState('true')
  // const accessToken= getToken; 

  const menus = [
    { title: 'Dashboard', src: "Chart_fill",  action: ()=>setView('')},
    { title: 'View All Requests', src: "Chat",  action: ()=>setView('notifications')},
    { title: 'Payment Transactions', src: "pay",  action: ()=>setView('payments')},
    { 
      title: 'Accounts', 
      src: "User", 
      gap: true,
      action: ()=>setView('accounts')
    },
    {
      title: 'Maps', 
      src: "maps",
      action: ()=>setView('maps')
    },
    // { title: 'Schedule', src: "Calendar"},
    // { title: 'Search', src: "Search"},
    // { title: 'Analytics', src: "Chart"},
    // { title: 'Files', src: "Folder", gap: true},
    { title: 'Main Menu', src: "control", gap: true , 
      action: ()=>{
        // console.log('logging out ')
        window.location.href = '/';
      }
    },
  ]

  function dashSwitch(){
    switch(view){
      case 'accounts':
        return <Accounts/>
      case 'notifications':
        return <Notifications/>
      case 'maps':
        return <Maps/> 
      case 'payments':
        return <Payments/>     
      default:
        return <Dash/>
    }
  }

  function Dash(){
    return(
      <section>
        <div className='front-semibold text-2xl'>
            <h2 className='font-semibold text-gray-700'>Dashboard</h2>
            <span className="text-xs text-gray-500">Summary of activities at MyRidex</span>
            <hr />
        </div>
        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem quibusdam voluptates velit, reiciendis doloribus laudantium sed quam distinctio temporibus commodi aliquam adipisci quia illo praesentium at laborum minus corrupti tenetur?</p> */}
        <Metrics data={data}/>
      </section>
    )
  }

  return (
    <div className='flex overflow-hidden'>
      <div className={`${open ? 'w-72': 'w-20'} duration-300 p-5 pt-8 h-screen bg-dark-purple relative`} style={{background: 'black'}}>
        <img 
          src={require('../../assets/img/control.png')} 
          alt="Ridex Logo"
          className={`absolute cursor-pointer -right-3 top-9 w-7 bottom-2 border-dark-purple rounded-full ${!open && 'rotate-180'}`}
          onClick={() => setOpen(!open)}
        />
        <div className='flex gap-x-4 items-center'>
          <img 
            src={require('../../assets/img/ridex-logo.png')} 
            alt="Ridex Logo"
            className={`cursor-pointer duration-300 ${!open && 'rotate-[360deg]'}`}
            width={40}
          />
          <h1 className={`self-center text-xl font-semibold whitespace-nowrap dark:text-white text-white origin-left font-medium text-x1 duration-300 ${!open && 'scale-0'}`}>MyRidex</h1>
        </div>
            <ul className='pt-6'>
              {menus.map((menu, index)=>(
                <li 
                  key={index} 
                  className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md ${menu.gap ? 'mt-9': 'mt-2'}`}
                  onClick={menu.action}
                >
                  <img 
                    src={require(`../../assets/img/${menu.src}.png`)} 
                    alt="Ridex Logo"
                    className={`cursor-pointer duration-300`}
                    width={25}
                  />
                  <span className={`text-white origin-left font-medium text-x1 duration-200 ${!open &&'hidden'}`}>{menu.title}</span>
                </li>
              ))}
            </ul>
      </div>
      <div className='p-8 flex-1 h-screen overflow-hidden overflow-y-auto'>
        {/* <div className='bg-black w-full h-10 p-2 flex justify-between items-center overflow-x-scroll py-10 lg:overflow-visible ' style={{background: 'black'}}>
          <p>hhh</p>
              <ul className='flex gx-5'>
                <li className='text-white mx-3'>Home</li>
                <li className='text-white mx-3'>Home</li>
                <li className='text-white mx-3'>Home</li>
                <li className='text-white mx-3'>Home</li>
              </ul>
        </div> */}

        {/* <ul class="flex flex-wrap items-center justify-center text-gray-900 dark:text-white">
            <li>
                <a href="#" class="me-4 hover:underline md:me-6 ">About</a>
            </li>
            <li>
                <a href="#" class="me-4 hover:underline md:me-6">Premium</a>
            </li>
            <li>
                <a href="#" class="me-4 hover:underline md:me-6 ">Campaigns</a>
            </li>
            <li>
                <a href="#" class="me-4 hover:underline md:me-6">Blog</a>
            </li>
            <li>
                <a href="#" class="me-4 hover:underline md:me-6">Affiliate Program</a>
            </li>
            <li>
                <a href="#" class="me-4 hover:underline md:me-6">FAQs</a>
            </li>
            <li>
                <a href="#" class="me-4 hover:underline md:me-6">Contact</a>
            </li>
        </ul> */}

        <div className='h-lvh'>
          {dashSwitch()}
        </div>


        {/* <footer className="bg-white shadow dark:bg-gray-800">
            <div className='p-4 text-white'>
              <p>weblotts.com</p>
            </div>
        </footer> */}
        

      </div>  
    </div>
  )
}
