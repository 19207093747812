import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    dash: false,
}

const appSlice = createSlice({
    name: 'apptype',
    initialState,
    reducers: {
        setAppState: ( state, action)=>{
            state.dash = true;
        }
    }
})

export default appSlice.reducer;
export const { setAppState } = appSlice.actions