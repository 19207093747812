import { View, Text } from 'react'
import React,{ useState, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
const UsersChart = (props) => {
  const [providersCount, setProvidersCount] = useState(props?.data.total_providers||0);
  const [customersCount, setCustomersCount] = useState(props?.data.total_customers||0);

//   useEffect(() => {
//     // Simulate fetching data from an API
//     const fetchCounts = async () => {
//       // Assuming an API call to fetch counts of providers and customers
//       const response = await fetch('https://api.example.com/count');
//       const data = await response.json();
//       setProvidersCount(data.providersCount);
//       setCustomersCount(data.customersCount);
//     };

//     fetchCounts();
//   }, []); 

const data = [
    { name: 'Providers', count: providersCount },
    { name: 'Customers', count: customersCount },
  ];

  return (
    <div>
      <BarChart width={450} height={400} data={data}>
            <CartesianGrid />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#1e3a8a" barSize={80} />
        </BarChart>
    </div>
  )
}

export default UsersChart