import React from 'react'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function (props) {
    const { label, options, placeholder, onChange, value, ...rest } = props;

    return(
        <div className='mb-6'>
            <label className="block text-sm font-medium text-gray-900 dark:text-white mb-1">{label}</label>
            <Dropdown 
                options={options} 
                placeholder={placeholder} 
                onChange={onChange}
                value={value}
                {...rest}/>
        </div>
    )
}
