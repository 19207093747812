import React, { useEffect, useState } from 'react'
import { axiosRequest } from '../../services/axiosRequest';
import moment from 'moment'

export default function Notifications() {

  const [ transactionHistory, setTransactionHistory ] = useState([]);
  
  async function getAdminTransactionHistory(){
    try {
      await axiosRequest('authentication/user/admin_dashboard/provider_requests', 'get')
      .then(res=> {
        // console.log("______",res.data)
        setTransactionHistory(res.data.data?.reverse())
      })
    } catch (error) {
      console.log(error)
    }
  }
  function dateFormat(date) {
    return moment(date).format('l')
  }
  useEffect(()=>{
    getAdminTransactionHistory();
  }, [])
  
  function ShowHistory(){
    let data = transactionHistory?.map((h, i) => {
      return (
        <tr key={i}>
          <td className="border-b border-gray-200 bg-white px-1 py-3 text-sm">
              <p className="whitespace-no-wrap">{i+1}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-1 py-3 text-sm">
            <p className="whitespace-no-wrap">{h?.customer_info?.first_name}{' '}{h?.customer_info?.last_name}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-1 py-3 text-sm">
            <p className='whitespace-nowrap px-0 py-3'>{h?.provider_info?.first_name}{' '}{h?.provider_info?.last_name}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-1 py-3 text-sm">
            <p className="whitespace-no-wrap">{h?.provider_business_info?.company_name}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-1 py-3 text-sm">
            <p className="whitespace-no-wrap">{new Intl.NumberFormat('en-US').format(h?.provider_amount)}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-1 py-3 text-sm">
            <p className="whitespace-no-wrap">{h?.created_at}</p>
          </td>
          <td >
            <p className="whitespace-nowrap px-0 py-3">{h?.status}</p>
          </td>
        </tr>
      )
    })
    return data
  }

  

  return (
    <section>
        <div className='front-semibold text-2xl'>
            <h1>Request History</h1>
            <span className="text-xs text-gray-500">A list of all Requests taking place with MyRidex</span>
            <hr />
        </div>
        <div className="">
          <div className="overflow-y-hidden border">
            <div className="overflow-x-auto">
              <table className="w-full hoverTable">
                <thead>
                    <tr className="bg-blue-600 text-left text-xs font-semibold uppercase tracking-widest text-white">
                        <th className="px-1 py-3">No.</th>
                        <th className="px-0 py-3">Sender</th>
                        <th className="px-0 py-3">Receiver</th>
                        <th className="px-0 py-3">Company</th>
                        <th className="px-0 py-3">Amount (UGX)</th>
                        <th className="px-0 py-3">Created at</th>
                        <th className="px-0 py-3">Status</th>
                    </tr>
                </thead>
                <tbody className="text-gray-500">                                    
                    <ShowHistory/>
                </tbody>
              </table>
            </div>
          </div>
        </div>

    </section>
  )
}
