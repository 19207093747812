import axios from "axios";
import { getUser } from "../utils/HelperFunction";


const abortController = new AbortController();
// const url = "http://localhost:8000";
const baseUrl = "https://myridex.tech/api/v1/";

export const axiosRequest = async ( endpoint, method='POST', postData = {},) => {
    let user = getUser()
    try {
        let configuration = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': user ?`Bearer ${user.access_token}`: '',
            },
            method: method,
            url: `${baseUrl}${endpoint}`,
            data: postData,
            signal: abortController.signal
        }

        let result = await axios.request(configuration)
        console.debug('Result from the request', )
        return result;
    }
    catch (error) {
        if(abortController.signal.aborted){
            console.log("Process aborted");
        }
        console.error(error);
    }
};