import { useFormik } from 'formik'
import React from 'react'
import InputComponent from '../../components/FormInput/InputComponent'
import ButtonComponent from '../../components/Button/ButtonComponent'
import { useDispatch } from 'react-redux';
import { signupUser } from '../../store/features/auth/signupThunk';

export default function SignupForm() {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            firstname: '',
            lastname: '',
            phonenumber: '',
            dob: '',
            password: '',
            confirmPassword: ''
        },
        onSubmit: (values) => {
            let postData = {
                email: values?.email,
                first_name: values?.firstname,
                last_name: values?.lastname,
                phone_number: values?.phonenumber,
                date_of_birth: values?.dob,
                password: values?.password,
                role_id: "1",
            }
            dispatch(signupUser(postData)).then(response => {
                console.log('hello',response.payload)
                if(response.payload.status === 1){
                    console.log("we have a successful return ") 
                    // navigate('/dashboard', { refresh: true })
                }else{
                    alert(response.payload.message)
                }
            })
            .catch(err => {
                alert(err.message)
            })
        }
    })

    return (
        <form>
            <InputComponent
                placeholder="Enter your first name"
                value={formik.values.firstname}
                label="First Name"
                onChange={formik.handleChange}
                id="firstname"
                type="text"
            />
            <InputComponent
                placeholder="Enter your last name"
                value={formik.values.lastname}
                label="Last Name"
                onChange={formik.handleChange}
                id="lastname"
                type="text"
            />
            <InputComponent
                placeholder="Enter your email"
                value={formik.values.email}
                label="Email"
                onChange={formik.handleChange}
                id="email"
                type="email"
            />
            <InputComponent
                placeholder="Enter your phone number"
                value={formik.values.phonenumber}
                label="Phone Number"
                onChange={formik.handleChange}
                id="phonenumber"
                type="phone"
            />
            <InputComponent
                placeholder="Enter your date of birth"
                value={formik.values.dob}
                label="Date of Birth"
                onChange={formik.handleChange}
                id="dob"
                type="date"
            />
            <InputComponent
                placeholder="Enter your password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                label="Password"
                id="password"
            />
            <InputComponent
                placeholder="Confirm your password"
                type="password"
                label="Confirm password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                id="confirmPassword"
            />         

            <ButtonComponent
                title="Sign up"
                onPress={formik.handleSubmit}
            />
        </form>
    )
}
