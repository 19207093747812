import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosRequest } from "../../../services/axiosRequest"

const initialState = {
    loading: false,
    accounts: [],
    error: ''
}

export const fetchAccounts = createAsyncThunk('acccounts/fetchAccounts', async ()=>{
    try {
        return await axiosRequest('authentication/user/all', 'get').then(res=> res.data) 
    } catch (error) {
        console.error(error)
    }
})

const accountSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAccounts.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchAccounts.fulfilled, (state, action) => {
            state.accounts = action.payload;
            state.loading = false;
            state.error = '';
        })
        builder.addCase(fetchAccounts.rejected, (state, action) => {
            state.loading = false;
            state.accounts = [];
            state.error = action.error.message;
        })
    }
})

export default accountSlice.reducer