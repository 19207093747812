import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import InputComponent from '../../components/FormInput/InputComponent'
import { useDispatch } from 'react-redux'
import { login } from '../../store/features/auth/authThunk'
import { useNavigate } from 'react-router-dom'
import ButtonComponent from '../../components/Button/ButtonComponent'


export default function LoginForm() {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values) => {
            dispatch(login(values))
              .then(response => {
                console.log('login response:', response.payload);
                if (response.payload && response.payload.status === 1) {
                  console.log("Login successful");
                  window.location.href = '/'; // Change location
                  console.log("checking to see if this run after ")
                } else {
                  console.log("Login failed");
                  // Handle failed login, maybe show an error message
                }
              })
              .catch(err => {
                // alert(err.message);
                console.log(err.message)
              });
          }
    })
    useEffect(()=>{
        // axiosRequest()
    },[])

    return (
        <form>
            <InputComponent
                placeholder="Enter your email"
                value={formik.values.email}
                label="Email"
                onChange={formik.handleChange}
                id="email"
            />
            <InputComponent
                placeholder="Enter your password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                label="Password"
                id="password"
            />
            <ButtonComponent
                title="Sign in"
                onPress={formik.handleSubmit}
            />
        </form>
    )
}
