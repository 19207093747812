import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { getToken, getUser } from '../../../utils/HelperFunction';
import axios from 'axios';
import PaymentLoader from './PaymentLoader';
export default function Dashboard(props) {
    useEffect(()=>{
      getRequestsHistory()
    },[])
    const [responseMessage, setResponseMessage]= useState(false)  
    const [successMessage, setSuccessMessage] = useState(true)
    const [requestResponseMessage, setRequestResponseMessage] = useState('');
    const [data, setData] = useState([]); 

    const { onClick } = props
    const extractGasName = (orderDetails) => {
        const match = orderDetails?.match(/GasName:([^,]+)/);
        return match ? match[1].trim() : '';
      };
      const extractGasWeight = (orderDetails) => {
        const match = orderDetails?.match(/GasWeight:([^,]+)/);
        return match ? match[1].trim() : '';
      };
  
      const extractVehiclePart = (orderDetails) => {
        const match = orderDetails?.match(/VehiclePart:([^,]+)/);
        return match ? match[1].trim() : '';
      };
      const extractDate = (orderDetails) => {
        const match = orderDetails?.match(/Requested at:([^,]+)/);
        return match ? match[1].trim() : '';
      };
  
      const extractGasType = (orderDetails) => {
        const match = orderDetails?.match(/GasType:([^,]+)/);
        return match ? match[1].trim() : '';
      };

      const token_tk = getToken();
      const user_info = getUser();

      function handlePayRequest(request){
        console.log(request.requestId)
        if(request.requestId){
          MakePayment(request.requestId)
       }   
      }
      const MakePayment = async (request_id)=> {
        console.log('attempting to make payment')
        let data = JSON.stringify({
          "status": "ACCEPTED",
          "updatedBy": "CUSTOMER"
        });
    
        let config = {
          method: 'post',
        //   maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/requests/requests/${request_id}/status/makePayment`,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+token_tk 
            
          },
          data : data
        };

       
        await axios.request(config)
    .then((response) => {
      console.log(response.data)
       setRequestResponseMessage('A payment message has been authorized, Please check your mobile device')
        setResponseMessage(true)
        setSuccessMessage(true)
        getRequestsHistory()

    })
    .catch((error) => {
      console.log(error);
    setRequestResponseMessage(error.message)
        setResponseMessage(true)
        setSuccessMessage(false)
    });

    }


    function handleCancelRequest(request){
        console.log(request.requestId)
        if(request.requestId){
          cancelRequest(request.requestId)
          getRequestsHistory()
        }
        
      }




    const cancelRequest = async(request_id)=>{
        console.log(request_id)
        let data = JSON.stringify({
          "status": "CANCELLED",
          "reason": "Taking too long to arrive",
          "updatedBy": "CUSTOMER"
        });
    
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/requests/requests/${request_id}/status`,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+ token_tk 
          },
          data : data
        };
    
        // console.log(config)
        // console.log(request_id)
    
        await axios.request(config)
        .then((response) => {
        setRequestResponseMessage(' Request has been cancelled successfully')
        setResponseMessage(true)
        setSuccessMessage(true)


        })
        .catch((error) => {
        //   console.error("an error occured"+error);
              setRequestResponseMessage(error.message)
        setResponseMessage(true)
        setSuccessMessage(false)
        });
      }

      const contactProvider = async (request)=>{
        console.log(request)
        // return ''
        let data = JSON.stringify({
         "messageTitle":"Gas Request To Mark",
        "messageBody":"Please Check your Requests ", 
        "userId":145
        });
    
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api/v1/authentication/user/fcm_manager/send-user-fcm-token`,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+ token_tk 
          },
          data : data
        };
    
        await axios.request(config)
        .then((response) => {
        setRequestResponseMessage('A message Has Been sent to the provider Being Requested')
        setResponseMessage(true)
        setSuccessMessage(true)


        })
        .catch((error) => {
        //   console.error("an error occured"+error);
              setRequestResponseMessage(error.message)
        setResponseMessage(true)
        setSuccessMessage(false)
        });
      }

      const refreshPaymentUrl = async (referenceId) =>{

        // return '';
        let configure = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/pay/transaction-status/${referenceId}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+token_tk 
           }
          }
          await axios.request(configure)
        .then((response) => {
          console.log('Getting Token Status',response.data);
          getRequestsHistory() 
        })
        .catch((error) => {
          console.log('Error Refreshing the Payment:',error.message);
        });
      }


      const getRequestsHistory = async ()=>{
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/requests/requests/-/customers/summary/${user_info?.user_id}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+token_tk 
           }
        };
        // console.log('Requesting ID: ',config)
        await axios.request(config)
        .then((response) => {
          console.log('Hello here',response.data);
          setData(response.data)
          // setData(false)
        })
        .catch((error) => {
          console.log('History error:',error);
        });
      }


  return (
    <section id="contact" className="ud-contact py-20 md:py-[40px] relative">
        <div className="absolute z-[-1] w-full h-1/2 lg:h-[45%] xl:h-1/2 bg-[#f3f4fe] top-0 left-0 "></div>
        
        <div className="container px-4">
            <div className="flex flex-wrap items-center -mx-4">
            <div className="px-4 w-full lg:w-7/12 xl:w-8/12">
                <div className="ud-contact-content-wrapper">
                <div className="ud-contact-title mb-12 lg:mb-[80px]">
                    <span
                    className="
                        font-headingFont
                        text-dark
                        font-semibold
                        text-base
                        mb-5
                    "
                    >
                    Hello,
                    </span>
                    <h2 className="text-[35px] font-semibold">
                    Quick menu,<br/> All services in one place.
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between mb-12 lg:mb-0">
                    <div className="flex max-w-full w-[330px]">

                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-[48px] h-[48px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.1" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>

                        <a href="/request">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Make a request</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Order gas refill, purchase and more</p>
                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                            Contact us for help
                            <svg class="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                            </svg>
                        </a>
                    </div>
                    
                    </div>
                    
                </div>
                </div>
            </div>
            <div className="px-0 w-full lg:w-5/12 xl:w-4/12">
                <div
                className="shadow-testimonial rounded-lg bg-white py-10 px-8 md:p-[60px] lg:p-10 2xl:p-[60px] sm:py-12 sm:px-10 lg:py-12 lg:px-10 wow fadeInUp" data-wow-delay=".2s">
                <h3 className="font-semibold mb-8 text-2xl md:text-[26px]">
                    Your Request History
                </h3>
                
                <div class="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    
                    {   
                    data.length > 0 ? (

                        data?.slice(0, 2).map(request => {
                            return (
                                <div>                                             
                            <div class="mx-auto my-2  flex max-w-sm flex-col bg-slate-200 items-center gap-3 rounded-xl border border-gray-100 py-4 text-gray-900 shadow-lg px-5">
                    {/* <div class="mx-auto my-4 flex max-w-sm flex-col items-center gap-3 rounded-xl border border-gray-100 py-8 text-gray-900 shadow-lg"> */}
                        <div class="flex">
                            <button class="rounded-lg bg-black py-2 px-4 text-sm text-white outline-none transition focus:ring active:bg-blue-500 active:text-white">{request?.serverType} SERVICE</button>
                            {/* <button class="mx-1 rounded-lg border bg-white py-1.5 px-4 text-gray-400 outline-none transition focus:ring active:bg-blue-500 active:text-white"> */}
                            {/* <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm-.67 2L12 10.75L5.67 6ZM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2a1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1Z" /></svg> */}
                            {/* </button> */}
                        </div>
                        <div class="flex flex-col items-center text-center ">
                            <h4 class="text-lg font-medium sm:m-0">{request?.providerAmount} UGX</h4>
                            <p class={`font-sans font-bold text-sm tracking-normal ${request.status === 'COMPLETED' ? 'text-green-500' : request.status === 'PENDING' ? 'text-yellow-500' : request.status === 'ACCEPTED' ? 'text-yellow-500' : 'text-red-500'}`}>{request?.status==="PENDING"?"PENDING APPROVAL":request?.status}</p>
                            <p class="font-sans text-sm tracking-normal text-gray-500">{extractGasName(request?.orderDetails)+" "+extractGasWeight(request?.orderDetails)+" "+extractGasType(request?.orderDetails)}</p>
                        </div>
                        
                        <div class="mt-2 flex w-full justify-between px-2 space-x-2">
                            {request?.status === 'ACCEPTED' && (!request?.transacctionStatus || request?.transacctionStatus === 'FAILED') &&(
                            <button class="flex items-center rounded-lg bg-blue-500 px-6 py-1 text-sm font-medium text-white outline-none transition focus:ring active:bg-blue-500 active:text-white"
                            onClick={  
                                ()=>{handlePayRequest(request)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                            PAY
                            </button>
                        )}

                            {
                                (request?.status === 'ACCEPTED' && (request?.transacctionStatus ==null || request?.transacctionStatus ==="FAILED"))&&(
                                    
                            <button class="flex items-center rounded-lg  bg-red-500 px-4 py-1 text-sm font-medium text-white outline-none transition focus:ring active:bg-red-300 active:text-white"
                            onClick={()=>{handleCancelRequest(request)}}>
                            CANCEL
                            </button>
                            )
                        }
                        </div>
                        <div>
                          { request?.transacctionStatus === "PENDING"&& (
                            <>
                            <div>
                              <PaymentLoader/>
                            </div>
                            <button class="flex items-center rounded-lg bg-green-500 px-6 py-1 text-sm font-medium text-white outline-none transition focus:ring active:bg-green-500 active:text-white"
                            onClick={  
                                ()=>{console.log(request?.x_referenceId)
                                  refreshPaymentUrl(request?.x_referenceId)
                                  }}>
                            refresh Payment
                            </button>
                            </>
                          )}
                        </div>
                        <div>
                        {(request?.status ==="ACCEPTED" || request?.status ==="PENDING")  && (

                        <button class="flex items-center rounded-lg px-4 py-1 text-sm font-medium bg-gray-400 text-white outline-none transition focus:ring active:bg-blue-500 active:text-white"
                        onClick={()=>{
                            console.log("attempting to Contact sending a notification to provider")
                            contactProvider(request)
                        }
                        }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                            </svg>
                            Contact Provider
                            </button>
                        
                        )}
                        </div>
                    </div>


                  </div>
                            )
                        }
                      )
                      
                      ):
                        (
                        <>
                        <p>Loading Information ... </p>
                        <span class="block h-20 w-72 rounded-lg bg-gradient-to-r from-gray-200 to-gray-200/25 animate-pulse"></span> 
                        <br/>
                        <span class="block h-30 w-72 rounded-lg bg-gradient-to-r from-gray-200 to-gray-200/25 animate-pulse"></span>
                        <br/>
                        <span class="block h-20 w-72 rounded-lg bg-gradient-to-r from-gray-200 to-gray-200/25 animate-pulse"></span>
                        </>
                        )
                    }
                    
                </div>

                <div className='mt-10'>
                    <Link onClick={onClick}>View all</Link>
                </div>
                <div>
                    {responseMessage &&(
                            <div className=' z-50 flex items-center justify-center fixed inset-0' >
                            <div class="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-12 py-6 shadow-md">
                            <button class="absolute top-0 right-0 p-4 text-gray-400" onClick={()=>{setResponseMessage(false)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-5 w-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <p class="relative mb-1 text-sm font-medium">
                                {successMessage===true?  <span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                </span>:<span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                </span>}
                                {successMessage===true?<span class="text-gray-50">Success!</span>: <span class="text-gray-50">Error!</span> }
                            </p>    
                            <p class="text-sm text-gray-200">{requestResponseMessage}</p>
                            </div>
                            </div>
                    )}
                </div>
                </div>
            </div>
            </div>
            
        </div>
    </section>
  )
}
