import { createSlice } from '@reduxjs/toolkit';

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: {
    data: null,
    // info:null,
  },
  reducers: {
    setDashboardData: (state, action) => {
      state.data = action.payload;
    //   console.log('we are in the setDashboardData')
    },
    setNewData:(state,action)=>{
        state.info = action.payload
    }
  },
});

export const { setDashboardData } = dashboardDataSlice.actions;

export const selectDashboardData = (state) => state.dashboardData;

export default dashboardDataSlice.reducer;