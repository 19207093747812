import React from 'react'

export default function ButtonComponent(props) {
    const {onPress, title} = props;
  return (
    <div className="mb-10">
        <button
            className="
            w-full
            rounded-md
            border
            bordder-primary
            py-3
            px-5
            bg-primary1
            text-base text-white
            cursor-pointer
            hover:shadow-md
            transition
            duration-300
            ease-in-out" type='submit'
            onClick={onPress}
        >
            {title}                   
        </button>
    </div>
  )
}
