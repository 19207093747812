import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import { getToken, getUser } from '../../../utils/HelperFunction';
import axios from 'axios';
import PaymentLoader from './PaymentLoader';
export default function Requests(props) {
 
    const { onClick } = props;
    const [responseMessage, setResponseMessage]= useState(false)  
    const [successMessage, setSuccessMessage] = useState(true)
    const [requestResponseMessage, setRequestResponseMessage] = useState('');
    const [data, setData] = useState([]); 
    useEffect(()=>{
        getRequestsHistory()
      },[data])
    const extractGasName = (orderDetails) => {
        const match = orderDetails?.match(/GasName:([^,]+)/);
        return match ? match[1].trim() : '';
      };
      const extractGasWeight = (orderDetails) => {
        const match = orderDetails?.match(/GasWeight:([^,]+)/);
        return match ? match[1].trim() : '';
      };
  
      const extractVehiclePart = (orderDetails) => {
        const match = orderDetails?.match(/VehiclePart:([^,]+)/);
        return match ? match[1].trim() : '';
      };
      const extractDate = (orderDetails) => {
        const match = orderDetails?.match(/Requested at:([^,]+)/);
        return match ? match[1].trim() : '';
      };
  
      const extractGasType = (orderDetails) => {
        const match = orderDetails?.match(/GasType:([^,]+)/);
        return match ? match[1].trim() : '';
      };

      const token_tk = getToken();
      const user_info = getUser();

      function handlePayRequest(request){
        console.log(request.requestId)
        if(request.requestId){
          MakePayment(request.requestId)
       }   
      }
      const MakePayment = async (request_id)=> {
        console.log('attempting to make payment')
        let data = JSON.stringify({
          "status": "ACCEPTED",
          "updatedBy": "CUSTOMER"
        });
    
        let config = {
          method: 'post',
        //   maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/requests/requests/${request_id}/status/makePayment`,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+token_tk 
            
          },
          data : data
        };

       
        await axios.request(config)
    .then((response) => {
      console.log(response.data)
       setRequestResponseMessage('A payment message has been authorized, Please check your mobile device')
        setResponseMessage(true)
        setSuccessMessage(true)
        getRequestsHistory()

    })
    .catch((error) => {
      console.log(error);
    setRequestResponseMessage(error.message)
        setResponseMessage(true)
        setSuccessMessage(false)
    });

    }


    function handleCancelRequest(request){
        console.log(request.requestId)
        if(request.requestId){
          cancelRequest(request.requestId)
          getRequestsHistory()
        }
        
      }




    const cancelRequest = async(request_id)=>{
        console.log(request_id)
        let data = JSON.stringify({
          "status": "CANCELLED",
          "reason": "Taking too long to arrive",
          "updatedBy": "CUSTOMER"
        });
    
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/requests/requests/${request_id}/status`,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+ token_tk 
          },
          data : data
        };
    
        // console.log(config)
        // console.log(request_id)
    
        await axios.request(config)
        .then((response) => {
        setRequestResponseMessage(' Request has been cancelled successfully')
        setResponseMessage(true)
        setSuccessMessage(true)
        getRequestsHistory() 

        })
        .catch((error) => {
        //   console.error("an error occured"+error);
              setRequestResponseMessage(error.message)
        setResponseMessage(true)
        setSuccessMessage(false)
        });
      }

      const contactProvider = async (request)=>{
        console.log(request)
        // return ''
        let data = JSON.stringify({
         "messageTitle":"Gas Request To Mark",
        "messageBody":"Please Check your Requests ", 
        "userId":145
        });
    
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api/v1/authentication/user/fcm_manager/send-user-fcm-token`,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+ token_tk 
          },
          data : data
        };
    
        await axios.request(config)
        .then((response) => {
        setRequestResponseMessage('A message Has Been sent to the provider Being Requested')
        setResponseMessage(true)
        setSuccessMessage(true)


        })
        .catch((error) => {
        //   console.error("an error occured"+error);
              setRequestResponseMessage(error.message)
        setResponseMessage(true)
        setSuccessMessage(false)
        });
      }

      const refreshPaymentUrl = async (referenceId) =>{

        // return '';
        let configure = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/pay/transaction-status/${referenceId}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+token_tk 
           }
          }
          await axios.request(configure)
        .then((response) => {
          console.log('Getting Token Status',response.data);
          getRequestsHistory() 
        })
        .catch((error) => {
          console.log('Error Refreshing the Payment:',error.message);
        });
      }


      const getRequestsHistory = async ()=>{
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://myridex.tech/api-v1/requests/requests/-/customers/summary/${user_info?.user_id}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+token_tk 
           }
        };
        // console.log('Requesting ID: ',config)
        await axios.request(config)
        .then((response) => {
          console.log('Hello here',response.data);
          setData(response.data)

        })
        .catch((error) => {
          console.log('History error:',error);
        });
      }

  return (
    <section id="contact" className="ud-contact py-20 md:py-[40px] relative">
        <div className="absolute z-[-1] w-full h-1/2 lg:h-[5%] xl:h-1/2 bg-[#f3f4fe] top-0 left-0 "></div>
        
        <div className="container px-4">
            <div className="flex flex-wrap -mx-4">
            <div className="px-4 w-full lg:w-7/12 xl:w-8/12">
                <div className="ud-contact-content-wrapper mb-10">
                <div className="ud-contact-title mb-12 lg:mb-[10px]">
                    <h2 className="text-[35px] font-semibold"> Your Requests.</h2>
                </div>
                    <dl class="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                        

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
  {data?.map(request => (
    <div key={request.id} className="flex flex-col bg-slate-200 rounded-lg border border-gray-100 shadow-md overflow-hidden">
      <div className="flex-grow p-4">
        <div className="flex flex-col items-center">
          <div className="w-full flex justify-center mb-3">
            <button className="w-full rounded-md bg-black py-2 px-4 text-sm text-white transition focus:ring active:bg-blue-500">
              {request?.serverType} SERVICE
            </button>
          </div>

          <div className="flex flex-col items-center text-center">
            <h4 className="text-lg font-semibold">{request?.providerAmount} UGX</h4>
            <p
              className={`font-sans font-bold text-sm ${
                request.status === "COMPLETED"
                  ? "text-green-500"
                  : request.status === "PENDING" || request.status === "ACCEPTED"
                  ? "text-yellow-500"
                  : "text-red-500"
              }`}
            >
              {request?.status==="PENDING"?"PENDING APPROVAL":request?.status}
            </p>
            <p className="font-sans text-sm text-gray-500">
              {extractGasName(request?.orderDetails)} {extractGasWeight(request?.orderDetails)} {extractGasType(request?.orderDetails)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex-shrink-0 p-4 flex flex-col space-y-2">
        <div className="mt-3 flex w-full justify-between space-x-2">
          {request?.status === "ACCEPTED" &&
            (!request?.transacctionStatus || request?.transacctionStatus === "FAILED") && (
              <>
                <button
                  className="flex items-center justify-center w-full rounded-md bg-blue-500 py-1 px-4 text-sm font-medium text-white transition focus:ring active:bg-blue-500"
                  onClick={() => handlePayRequest(request)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  PAY
                </button>
                <button
                  className="flex items-center justify-center w-full rounded-md bg-red-500 py-1 px-4 text-sm font-medium text-white transition focus:ring active:bg-red-300"
                  onClick={() => handleCancelRequest(request)}
                >
                  CANCEL
                </button>
              </>
            )}
        </div>

        {request?.transacctionStatus === "PENDING" && (
          <div className="w-full">
            <div className="flex justify-center">
              <PaymentLoader />
            </div>
            <button
              className="flex items-center justify-center w-full rounded-md bg-green-500 py-1 px-4 text-sm font-medium text-white transition focus:ring active:bg-green-500"
              onClick={() => {
                console.log(request?.x_referenceId);
                refreshPaymentUrl(request?.x_referenceId);
              }}
            >
              Refresh Payment
            </button>
          </div>
        )}

        {(request?.status === "ACCEPTED" || request?.status === "PENDING") && (
          <div className="w-full">
            <button
              className="flex items-center justify-center w-full rounded-md bg-gray-400 py-1 px-4 text-sm font-medium text-white transition focus:ring active:bg-blue-500"
              onClick={() => {
                console.log("attempting to Contact sending a notification to provider");
                contactProvider(request);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
              Contact Provider
            </button>
          </div>
        )}
      </div>
    </div>
  ))}
</div>

                        
                    </dl>
                </div>
            </div>
            {responseMessage &&(
    <div className=' z-50 flex items-center justify-center fixed inset-0' >
    <div class="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-12 py-6 shadow-md">
      <button class="absolute top-0 right-0 p-4 text-gray-400" onClick={()=>{setResponseMessage(false)}}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-5 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <p class="relative mb-1 text-sm font-medium">
        {successMessage===true?  <span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </span>:<span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
          </span>}
        {successMessage===true?<span class="text-gray-50">Success!</span>: <span class="text-gray-50">Error!</span> }
      </p>
      
      <p class="text-sm text-gray-200">{requestResponseMessage}</p>

    </div>
    </div>
      )}
            <div className="px-2 w-full lg:w-5/12 xl:w-4/12">
                <div
                className="shadow-testimonial rounded-lg bg-white py-10 px-8 md:p-[60px] lg:p-10 2xl:p-[60px] sm:py-12 sm:px-10 lg:py-12 lg:px-10 wow fadeInUp" data-wow-delay=".2s">
                <h3 className="font-semibold mb-8 text-2xl md:text-[26px]">
                    Actions
                </h3>
                
                
                <div className='mt-10'>
                    <Link onClick={onClick}>Return to account</Link>
                </div>

                </div>
            </div>
            </div>
        </div>
    </section>

  )
}
