import React, { useEffect, useState } from 'react'
import { axiosRequest } from '../../../services/axiosRequest'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Dashboard from './Dashboard'
import Requests from './Requests'
import { getUser } from '../../../utils/HelperFunction'

export default function UserAccount() {

    const [ user, setUser ] = useState({})
    const [ requestHistory, setRequestHistory ] = useState([])
    const [ screen, setScreen ] = useState('dashboard')
    const userObj = getUser()

    console.log(userObj)
    async function getUserProfile(){
        await axiosRequest('authentication/user/profile', 'get')
        .then(response => {
            setUser(response.data.data)
        })
        .catch(error => {
            console.log(error)
        })
    }

    /**
     * Retrieves the customer requests for a specific customer ID.
     *
     * @return {Promise<void>} - A promise that resolves when the requests are successfully retrieved.
     */
    async function getCustomerRequests(){
        console.log(userObj?.access_token )
        let config = {
            method: 'Get',
            maxBodyLength: Infinity,
            url: `https://myridex.tech/api-v1/requests/requests/-/customers/summary/${userObj?.user_id}`,
            headers: {
                'Authorization':`Bearer `+userObj?.access_token 
             }
        };
          
        axios.request(config)
          .then((response) => {
            console.warn("SUMAMRY REQUESTS   "+JSON.stringify(response));
            setRequestHistory(response.data)
        })
        .catch((error) => {
            console.log('Error:',error);
        });
    }

    useEffect(()=>{
        getUserProfile()
        getCustomerRequests()
    },[])


    function Account(typeOfScreen){
        switch(typeOfScreen){
            default: 
        }
    }

  return (
    <section>
        <div className="relative z-10 pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[100px] bg-primary1 overflow-hidden" >
            <div className="container">
                <div className="flex flex-wrap items-center -mx-4">
                    <div className="w-full px-4">
                        <div className="text-center">
                            <h1 className="font-semibold text-white text-4xl">{`${user?.first_name} ${user?.last_name}`}</h1>
                            <p className='text-white'>You are viewing this as a customer.</p>
                        </div>
                    </div>
                </div>
            </div>
        <div> 
        </div>
    </div>
    {
        screen === 'dashboard' ? <Dashboard data={requestHistory} onClick={()=>setScreen('')}/> : <Requests data={requestHistory} onClick={()=>setScreen('dashboard')}/>
    }
    {/* <Dashboard data={requestHistory}/> */}
    {/* <Requests data={requestHistory}/> */}
    </section>
  )
}
