// PaymentLoader.jsx
import React from 'react';

// Loader component for React
const PaymentLoader = () => {
  return (
    <div style={styles.container}>
      <p style={styles.text}>Processing payment...</p>
      <div className="spinner" style={styles.spinner}>
        <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g>
            <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="currentColor" strokeWidth="12"></path>
            <path d="M49 3L49 27L61 15L49 3" fill="currentColor"></path>
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </g>
        </svg>
      </div>
    </div>
  );
};

// Inline styles for the loader component
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row', // Align children horizontally
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    padding: '1rem',
    color: '#007bff',
  },
  text: {
    color: '#333',
    fontWeight: 'bold',
    marginRight: '0.5rem',
    fontSize: '0.875rem', // text-sm equivalent in Tailwind
  }
};

export default PaymentLoader;
