// AIzaSyCcBdz1dGf3g3AlW1LgAu9xFImR-_z1uMU
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';

const containerStyle = {
    width: '100%',
    height: '90svh'
};

function Maps() {
    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isReady, setIsReady] = useState(false);

    const [responseMessage, setResponseMessage] = useState(false);  
    const [successMessage, setSuccessMessage] = useState(true);
    const [requestResponseMessage, setRequestResponseMessage] = useState('');

    const accountRedux = useSelector((state) => state.accounts.accounts);
    const { loading } = useSelector((state) => state.accounts);

    const center = {
        lat: latitude,
        lng: longitude
    };

    const setMarkerForProviders = useCallback((accounts) => {
        let providers = [];
        accounts?.forEach((account) => {
            if (account?.role_id === 2 && account?.address?.coordinates[0] !== undefined) {
                providers.push({
                    lat: parseFloat(account?.address?.coordinates[0]),
                    lng: parseFloat(account?.address?.coordinates[1]),
                    name: account.first_name
                });
            }
        });
        return providers;
    }, []);

    const providerCoordinates = useCallback(() => {
        if (!accountRedux?.data) return;
        const providers = setMarkerForProviders(accountRedux.data);
        setMarkers(providers);
        setIsReady(true); // Set isReady to true once markers are ready
    }, [accountRedux.data, setMarkerForProviders]);

    useEffect(() => {
        getLocation();
    }, []); // Only call getLocation once on mount

    useEffect(() => {
        if (latitude !== null && longitude !== null) {
            providerCoordinates();
        }
    }, [latitude, longitude, providerCoordinates]); // Add providerCoordinates to dependencies

    const onLoad = useCallback(function callback(map) {
        if (latitude && longitude) {
            const bounds = new window.google.maps.LatLngBounds(center);
            map.fitBounds(bounds);
            setMap(map);
        }
    }, [latitude, longitude]);

    const onUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    console.error(error);
                    if (error.message === 'User denied Geolocation') {
                        setRequestResponseMessage("Please enable your browser location");
                        setResponseMessage(true);
                        setSuccessMessage(false);
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    function RenderMarker() {
        return (
            markers.map((marker, index) => (
                <AdvancedMarker key={index} position={{ lat: marker.lat, lng: marker.lng }}>
                    <div style={{ backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: 8 }}>
                        <h2>{marker.name}</h2>
                    </div>
                    <img src={require('../../assets/svgs/gear.webp')} alt="User Icon" style={{ width: '25px', height: '25px' }} />
                </AdvancedMarker>
            ))
        );
    }

    return (
        <section>
            <div className="front-semibold text-2xl">
                <h2 className="font-semibold text-gray-700">Providers on maps</h2>
                <span className="text-xs text-gray-500">View our providers on the map</span>
                <hr />
            </div>
            <div>
                {!loading && isReady ? (
                    <APIProvider apiKey={'AIzaSyCcBdz1dGf3g3AlW1LgAu9xFImR-_z1uMU'}>
                        <Map
                            style={{ width: '100%', height: '100vh' }}
                            defaultCenter={center}
                            defaultZoom={13}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                            mapId={'12344'}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            <AdvancedMarker position={center}>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '50%',
                                        border: '2px solid red',
                                        backgroundColor: 'rgba(255,0,0,0.2)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img src={require('../../assets/svgs/final.png')} alt="User Icon" style={{ width: '35px', height: '35px' }} />
                                </div>
                            </AdvancedMarker>
                            <RenderMarker />
                        </Map>
                    </APIProvider>
                ) : (
                    <div>
                        <p>Loading maps...</p>
                    </div>
                )}
            </div>
            {responseMessage && (
                <div className='z-50 flex items-center justify-center fixed inset-0'>
                    <div className="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-12 py-6 shadow-md">
                        <button className="absolute top-0 right-0 p-4 text-gray-400" onClick={() => { setResponseMessage(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <p className="relative mb-1 text-sm font-medium">
                            {successMessage ? (
                                <span className="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-3 w-3">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                </span>
                            ) : (
                                <span className="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-3 w-3">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                </span>
                            )}
                            {successMessage ? <span className="text-gray-50">Success!</span> : <span className="text-gray-50">Error!</span>}
                        </p>
                        <p className="text-sm text-gray-200">{requestResponseMessage}</p>
                    </div>
                </div>
            )}
        </section>
    );
}

export default memo(Maps);
