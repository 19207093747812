import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice"
import signupReducer from "./features/auth/signupSlice";
import accountsReducer from "./features/accounts/accountSlice";
import dashboardDataSlice from "./dashboardDataSlice";
import appReducer from "./features/app/appSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        accounts: accountsReducer,
        signup: signupReducer,
        dashboardData: dashboardDataSlice,
        app: appReducer
    }
});