
function NotFoundPage() {
    return (
        <section>
            <div className="relative z-10 pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[100px] bg-primary1 overflow-hidden" >
                <div className="container">
                    <div className="flex flex-wrap items-center -mx-4">
                      <div className="w-full px-4">
                          <div className="text-center">
                            <h1 className="font-semibold text-white text-4xl">404 Not Found</h1>
                          </div>
                      </div>
                    </div>
                </div>
            <div>            
            </div>
    </div>
    
    <section className="bg-white py-14 lg:py-20">
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div
              className="max-w-[850px] mx-auto text-center bg-white rounded-lg relative overflow-hidden py-20 px-8 sm:px-12 md:px-[60px] shadow-lg wow fadeInUp"
              data-wow-delay=".2s"
            >
              <h2 className="font-bold mb-8 text-dark text-3xl sm:text-4xl lg:text-[40px] xl:text-[42px]">
                404 - We couldnt find that page.
              </h2>
              <h3 className="font-normal mb-8 text-xl md:text-2xl text-dark-700">
                Maybe you can find what you need here?
              </h3>
              <ul className="flex flex-wrap justify-center">
                <li>
                  <a href="/" className="text-base font-medium py-3 px-6 text-dark rounded-md mx-2 my-1 inline-block bg-[#f5f8ff] hover:bg-primary hover:text-white">Home</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
</section>
    );
}

export default NotFoundPage;