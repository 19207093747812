import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../services/api";
import axios from "axios";

export const signupUser = createAsyncThunk(
    'auth/register', async(payload)=>{
        try {
            const response = await axios.post(`${baseUrl}api/v1/authentication/user/register`, payload)
            return response.data
        } catch (error) {
            console.log('An error has occured',error.message)
            return error
        }
    }
)