import { createSlice } from "@reduxjs/toolkit";
import { signupUser } from "./signupThunk";


const initialState = {
    loading: false,
    success: true,
    error: ''
}

export const signupSlice = createSlice({
    name:'signup',
    initialState,
    reducers: {},

    extraReducers:(builder)=> {
        builder.addCase(signupUser.pending, (state, action)=>{
            state.loading = true
        })
        builder.addCase(signupUser.fulfilled, (state, action)=>{
            state.loading = false
        })
        builder.addCase(signupUser.rejected, (state, action)=>{
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const { } = signupSlice.actions;
export default signupSlice.reducer;