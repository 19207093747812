import Clients from "./WebParts/Clients";

function HomePage() {
    return (
        <>
        <div
            id="home"
            className="relative pt-[120px] md:pt-[130px] lg:pt-[160px] bg-primary1 "
        >
            <div className="container">
                <div className="flex flex-wrap items-center -mx-4">
                    <div className="w-full px-4">
                        <div
                            className="hero-content text-center max-w-[780px] mx-auto wow fadeInUp"
                            data-wow-delay=".2s"
                        >
                           
                        </div>
                    </div>
        
                    <div className="w-full px-4">
                        <div
                            className="mx-auto max-w-[845px] relative z-10 wow fadeInUp"
                            data-wow-delay=".25s"
                        >
                            <div className="mt-16">
                            <img
                                src="https://myridex.com/wp-content/themes/myridex-theme/assets/img/demo.png"
                                alt="hero"
                                className="max-w-full mx-auto rounded-t-xl rounded-tr-xl"
                            />
                            </div>
                            
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            
      </div>
            {/* <Clients/> */}
        </>
    );
}

export default HomePage;