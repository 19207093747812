import React, { useEffect, useState } from 'react'
import { axiosRequest } from '../../services/axiosRequest';
import moment from 'moment'
import DataTable from 'react-data-table-component';
import { getToken} from '../../utils/HelperFunction'; //../../../utils/HelperFunction
import axios
 from 'axios';
export default function Payments() {

  // const [ paymentHistory, setPaymentHistory ] = useState([]);

  const [responseMessage, setResponseMessage]= useState(false)  
  const [successMessage, setSuccessMessage] = useState(true)
  const [requestResponseMessage, setRequestResponseMessage] = useState('');
  const [data, setData]=useState([]); 
  const [row_data,setRowData] = useState([]);
  const token_tk = getToken();

  async function getPaymentHistory(){
    try {
      await axiosRequest('authentication/user/admin_dashboard/provider_payment_information', 'get')
      .then(res=> {
        console.log("payment",res?.data?.transactions)
        // setPaymentHistory(res?.data?.transactions.reverse())
        setData(res?.data?.transactions.reverse())
        // co
      })
    } catch (error) {
      console.log(error)
    }
  }
  function dateFormat(date) {
    return moment(date).format('l')
  }
  useEffect(()=>{
    getPaymentHistory();
  }, [])
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const refreshPaymentUrl = async (referenceId) =>{

    // return '';
    let configure = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://myridex.tech/api-v1/pay/transaction-status/${referenceId}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer `+token_tk 
       }
      }
      await axios.request(configure)
    .then((response) => {
      console.log('Getting Token Status',response.data);
      setResponseMessage(false)
      // setSuccessMessage(true)
      getPaymentHistory();
    })
    .catch((error) => {
      console.log('Error Refreshing the Payment:',error.message);
    });
  }



  const columns = [
    {
      name: '#', // Numbering column
      selector: (row, index) => index + 1, // Display the row index, starting from 1
      cell: (row, index) => (
        <div className="text-gray-800 font-medium">{index + 1}</div>
      ),
      width: '50px', // Adjust the width as needed
      center: true, // Center-align the numbering
    },

    {
      name: 'Customer Name',
      selector: (row) => row.provider_request?.customer?.first_name || 'N/A' ,
      sortable: true,
    },
    {
      name: 'Customer Phone',
      selector: (row) => row.provider_request?.customer?.phone_number || 'N/A',
      sortable: true,
    },
    {
      name: 'Provider Name',
      selector: (row) => row.provider_request?.provider?.first_name || 'N/A',
      sortable: true,
    },
    {
      name: 'Provider Phone',
      selector: (row) => row.provider_request?.provider?.phone_number || 'N/A',
      sortable: true,
    },
    {
      name: 'Amount Ugx',
      selector: (row) => row?.amount || 'N/A',  // Display 'N/A' if amount is null
      sortable: true,
    },
    {
      name: 'Payment Status',
      selector: (row) => row?.status || 'N/A',
      sortable: true,
    },
    // {
    //   name: 'Order Details',
    //   selector: (row) => row.provider_request.order_details || 'N/A',
    //   sortable: false,  // Sorting might not be meaningful for detailed text
    // },
    {
      name: 'Actions',
      cell: (row, index) => (
        <div className="inline-flex items-center rounded-full bg-blue-200 py-1 px-2 text-blue-600">
          <button
            onClick={() => {
              console.log('the data us here ', row)
              setRequestResponseMessage(`showing the details of the request ${JSON.stringify(row)}`)
              setRowData(row)
              setResponseMessage(true)
              setSuccessMessage(true)
               // Logs the data of the row that is clicked
            }}
          >
            Info
          </button>
        </div>
      ),
      sortable: false,  // Sorting might not be meaningful for detailed text
      width: '80px', // Adjust the width as needed
      center: true, // Center-align the numbering
    }
  ];


  const [filterText, setFilterText] = useState('');
  const [filteredData, setFilteredData] = useState(data);


  // Filter data based on search input
  const handleSearch = (e) => {
    const query = e.target.value;
    setFilterText(query);
    const filteredItems = data.filter((item) =>
      item?.provider_request?.customer?.first_name
        .toLowerCase()
        .includes(query.toLowerCase())
    );
    setFilteredData(filteredItems);
  };

  // const handleSearch = (e) => {
  //   const query = e.target.value;
  //   setFilterText(query);
  
  //   const filteredItems = data.filter((item) => {
  //     // Check if the customer object or first_name is null or undefined
  //     const customerName = item?.provider_request?.customer?.first_name;
  
  //     // Include items where the customer's first name matches the query or is null/undefined
  //     if (customerName === null || customerName === undefined) {
  //       return true; // Include items with null or undefined customer names
  //     }
  
  //     // Include items where the customer's first name matches the query
  //     return customerName.toLowerCase().includes(query.toLowerCase());
  //   });
  
  //   setFilteredData(filteredItems);
  // };
  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
        backgroundColor: '#f4f4f4',
      },
    },
    headCells: {
      style: {
        paddingLeft: '10px', // override the default padding for head cells
        paddingRight: '16px',
        fontWeight: 'bold',
        fontSize: '14px',
        backgroundColor: '#36b6ff',
        color: 'white',
      },
    },
    cells: {
      style: {
        paddingLeft: '10px', // override the default padding for data cells
        paddingRight: '16px',
        fontSize: '13px',
        color: '#555',
      },
    },
    rows: {
      style: {
        minHeight: '72px', // override the row height
        backgroundColor: '#ffffff',
        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: '#e4e4e4',
        },
      },
      highlightOnHoverStyle: {
        backgroundColor: '#f2f2f2',
        borderBottomColor: '#FFFFFF',
        outline: '1px solid #ffffff',
      },
    },
    pagination: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#e3e3e3',
      },
      pageButtonsStyle: {
        color: '#000',
        fill: '#000',
        '&:disabled': {
          fill: '#e3e3e3',
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#e3e3e3',
        },
      },
    },
  }; 
  

  return (
    <section>
      <>
        <div className='front-semibold text-2xl'>
            {/* <h1>Payment History</h1>
            <span className="text-xs text-gray-500">A list of all transactions taking place with MyRidex</span>
            <hr /> */}
        </div>
        <div className="">
         
          <div className="overflow-y-hidden border">
          <h3 className="text-xl font-semibold text-gray-800 my-4">Transaction Details</h3>
          <span className="text-xs text-gray-500">A list of all transactions taking place with MyRidex</span>
          <div className=" flex justify-end">
        <input
          type="text"
          placeholder="Search by customer name"
          value={filterText}
          onChange={handleSearch}
          className="px-5 py-2 my-5 mx-4 border rounded shadow-sm focus:outline-none focus:border-blue-400"
          style={{ width: '250px' }}
        />
      </div>
      <div className='mx-2'>
      <DataTable
        // title="Transaction Details"
        columns={columns}
        data={filteredData}
        // data={data}
        customStyles={customStyles}
        pagination
        highlightOnHover
        className="rounded-lg shadow-lg"
        pointerOnHover
        responsive
        striped
      />
      </div>
    </div>
          
      </div>

      {/* {responseMessage &&(
    <div className=' z-50 flex items-center justify-center fixed inset-0' >
    <div class="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-12 py-6 shadow-md">
      <button class="absolute top-0 right-0 p-4 text-gray-400" onClick={()=>{setResponseMessage(false)}}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-5 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <p class="relative mb-1 text-sm font-medium">
        {successMessage===true?  <span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </span>:<span class="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="h-3 w-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
          </span>}
        {successMessage===true?<span class="text-gray-50">Success!</span>: <span class="text-gray-50">Error!</span> }
      </p>
      
      <p class="text-sm text-gray-200">{requestResponseMessage}</p>

    </div>
    </div>
      )} */}

{responseMessage && row_data && (
  <div className="z-50 flex items-center justify-center fixed inset-0">
    <div className="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-8 py-6 shadow-md">
      <button
        className="absolute top-0 right-0 p-4 text-gray-400"
        onClick={() => {
          setResponseMessage(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-5 w-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      {/* Status and Message */}
      <p className="relative mb-4 text-sm font-medium">
        {/* {successMessage === true ? (
          <span className="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-3 w-3"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </span>
        ) : (
          <span className="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-3 w-3"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </span>
        )} */}
        {/* <span className="text-gray-50">{row_data?.status === 'SUCCESSFUL' ? "Success" : row_data?.status === 'PENDING' ? "PENDING": row_data?.status === 'FAILED' ? "FAILED": "..."}</span> */}
      </p>

      {/* Request Details */}
      <div className="mb-4 text-sm text-gray-200">
        <h3 className="font-semibold text-gray-50">Request Details:</h3>
        <p>ID: {row_data?.id}</p>
        <p>Amount: {row_data?.amount}</p>
        <p>Currency: {row_data?.currency}</p>
        <p>Status: {row_data?.status}</p>
        <p>Payee Note: {row_data?.payee_note}</p>
        <p>Payer Message: {row_data?.payer_message}</p>
        <p>Reason: {row_data?.reason}</p>
        <p>Created At: {new Date(row_data?.created_at).toLocaleString()}</p>
        {row_data?.updated_at && <p>Updated At: {new Date(row_data?.updated_at).toLocaleString()}</p>}
      </div>

      {/* Provider Request Details */}
      <div className="mb-4 text-sm text-gray-200">
        <h3 className="font-semibold text-gray-50">Provider Request Details:</h3>
        <p>External ID: {row_data?.provider_request?.external_id}</p>
        <p>Customer ID: {row_data?.provider_request?.customer_id}</p>
        <p>Provider ID: {row_data?.provider_request?.provider_id}</p>
        <p>Status: {row_data?.provider_request?.status}</p>
        <p>Service Type: {row_data?.provider_request?.service_type}</p>
        <p>Order Details: {row_data?.provider_request?.order_details}</p>
      </div>

      {/* Customer Details */}
      <div className="mb-4 text-sm text-gray-200">
        <h3 className="font-semibold text-gray-50">Customer Details:</h3>
        <p>User ID: {row_data?.provider_request?.customer?.user_id}</p>
        <p>Name: {row_data?.provider_request?.customer?.first_name}</p>
        <p>Email: {row_data?.provider_request?.customer?.email}</p>
        <p>Phone: {row_data?.provider_request?.customer?.phone_number}</p>
      </div>

      {/* Provider Details */}
      <div className="text-sm text-gray-200">
        <h3 className="font-semibold text-gray-50">Provider Details:</h3>
        <p>User ID: {row_data?.provider_request?.provider?.user_id}</p>
        <p>Name: {row_data?.provider_request?.provider?.first_name}</p>
        <p>Email: {row_data?.provider_request?.provider?.email}</p>
        <p>Phone: {row_data?.provider_request?.provider?.phone_number}</p>
      </div>
      {row_data?.status==='PENDING'&&(
            <div className="mt-4 flex justify-center">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            // Handle the refresh logic here
            console.log("Refresh Payment clicked");
            refreshPaymentUrl(row_data?.x_reference_id)
            // Call your refresh function or logic here
          }}
        >
          Refresh Payment
        </button>
      </div>
      )}
    </div>
    
  </div>
)}
      </>
    </section>
  )
  
}
