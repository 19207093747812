import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import store from './store/store.js'
import { ThemeProvider } from "@material-tailwind/react";
import App from './App.jsx';
// import '../src/assets/js/main.js';
// import '../src/assets/js/wow.min.js';

ReactDOM.createRoot(document.getElementById('root')).render(
 
    <ThemeProvider>
      {/* <MaterialTailwindControllerProvider> */}
        <Provider store={store}>
            {/* <Dashboard/> */}
            <App/>
        </Provider>
      {/* </MaterialTailwindControllerProvider> */}
    </ThemeProvider>
  
)
