import React from 'react'

export default function InputComponent(props) {

    const { 
     type, value, name, placeholder, label, onChange, id, ...inputProps
    } = props;


  return (
    <div className="mb-6">
        <label className="block text-sm font-medium text-gray-900 dark:text-white text-left mb-1">{label}</label>
        <input 
            type={type}
            className="
              w-full
              rounded-md
              border
              bordder-[#E9EDF4]
              py-3
              px-5
              bg-[#FCFDFE]
              text-base text-body-color
              placeholder-[#ACB6BE]
              outline-none
              focus-visible:shadow-none
              focus:border-primary
              transition
            "
            placeholder={placeholder}
            value={value}
            id={id}
            onChange={onChange}
            {...inputProps}

        />
    </div>
  )
}
