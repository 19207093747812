export const getToken = () => {
    return localStorage.getItem('token');
}

export const setToken = (value) => {
    localStorage.setItem('token', value);
}

export const removeToken = () => {
    localStorage.removeItem('token');
}

export const setUser = (value) => {
    localStorage.setItem('user', JSON.stringify(value));
    
}

// export const getUser = () => {
//     return JSON.parse(localStorage.getItem('user'));
// }
export const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}

export const removeUser = () => {
    
    localStorage.removeItem('user');
}