import { useState } from 'react'

import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import { Link } from 'react-router-dom'

export default function AuthPage() {

    const [ login, setLogin] = useState(true)


    return (
        
        <>
        <div
      className="
        relative
        z-10
        pt-[120px]
        md:pt-[130px]
        lg:pt-[160px]
        pb-[10px]
        bg-primary1
        overflow-hidden
      "
    >
      <div className="container">
        
      </div>
      <div>
        <span className="absolute top-0 left-0 z-[-1]">
          <svg
            width="495"
            height="470"
            viewBox="0 0 495 470"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="55"
              cy="442"
              r="138"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="50"
            />
            <circle
              cx="446"
              r="39"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="20"
            />
            <path
              d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z"
              stroke="white"
              stroke-opacity="0.08"
              stroke-width="12"
            />
          </svg>
        </span>
        <span className="absolute top-0 right-0 z-[-1]">
          <svg
            width="493"
            height="470"
            viewBox="0 0 493 470"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="462"
              cy="5"
              r="138"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="50"
            />
            <circle
              cx="49"
              cy="470"
              r="39"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="20"
            />
            <path
              d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z"
              stroke="white"
              stroke-opacity="0.06"
              stroke-width="13"
            />
          </svg>
        </span>
      </div>
    </div>
    <section className="bg-[#F4F7FF] py-14 lg:py-20">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div
                className="
                  max-w-[525px]
                  mx-auto
                  text-center
                  bg-white
                  rounded-lg
                  relative
                  overflow-hidden
                  py-14
                  px-8
                  sm:px-12
                  md:px-[60px]
                  wow
                  fadeInUp
                "
                data-wow-delay=".15s"
              >
                <div className="mb-10 text-center">
                    <div className="flex flex-wrap items-center -mx-4">
                        <div className="w-full px-4">
                            <div className="text-center">
                            <h1 className="font-semibold text-gray text-4xl">{ login ? 'Sign in': 'Sign up'}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                
                { login ?  <LoginForm/> : <SignupForm/> }

                {
                    login &&
                    <a
                        href="javascript:void(0)"
                        className="
                            text-base
                            inline-block
                            mb-2
                            text-[#adadad]
                            hover:text-primary
                        "
                        >
                        Forget Password?
                    </a>
                }
                <p className="text-base text-[#adadad]">
                  Not a member yet?{' '}
                  <Link onClick={()=>setLogin(!login)} className="text-primary hover:underline">
                    { !login ? 'Sign in': 'Sign up'}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
            
        </>
    )
}
