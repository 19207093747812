import mqtt from "mqtt"

const protocol = 'wss'
const host = '4.156.26.184'
const port = '8083'
const clientId = `mqtt_${Math.random().toString(16).slice(3)}`


export default function mqtt_conn(){

    const connectUrl = `${protocol}://${host}:${port}`
    const client = mqtt.connect(connectUrl, {
    clientId,
    clean: true,
    connectTimeout: 4000,
    username: 'emqx',
    password: 'public',
    reconnectPeriod: 1000,
    })

    const topic = '/nodejs/mqtt'

    client.on('connect', () => {
    console.log('Connected')

    client.subscribe([topic], () => {
        console.log(`Subscribe to topic '${topic}'`)
        client.publish(topic, 'nodejs mqtt test', { qos: 0, retain: false }, (error) => {
        if (error) {
            console.error(error)
        }
        })
    })
    })

    client.on('message', (topic, payload) => {
    console.log('Received Message:', topic, payload.toString())
    })

  }