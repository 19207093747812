import { View, Text } from 'react'
import React,{ useState, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import UsersChart from './UsersChart';
import TransactionChart from './TransactionChart';
const DashboardCharts = (props) => {



    return (
        <div>
          <div className="grid grid-cols-1 gap-8 mt-10 lg:grid-cols-2 xl:grid-cols-2 bg-white">
            <div className="flex-col items-center shadow-xl justify-between p-5 bg-white rounded-md border border-grey-900">
              <div style={{ marginBottom: '20px' }}>
                <h2 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>User Overview</h2>
              </div>
              <UsersChart data={props.data}/>
            </div>
      
            <div className="flex-col items-center shadow-xl justify-between p-5 bg-white rounded-md border border-grey-900">
              <div style={{ marginBottom: '20px' }}>
                <h2 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>All Monthly Total Transaction OverView Ugsh</h2>
              </div>
              <TransactionChart/>
            </div>
          </div>
          
          <div className="grid grid-cols-1 gap-8 mt-10 lg:grid-cols-2 xl:grid-cols-2 bg-white">
            {/* <div className="flex-col items-center shadow-xl justify-between p-5 bg-white rounded-md border border-grey-900"> */}
             {/* <TransactionChart/>  */}
            {/* </div> */}
          
          </div>

        </div>
      );
      
}

export default DashboardCharts