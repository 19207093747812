import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken, removeToken, setToken, setUser } from "../../../utils/HelperFunction";
import api, { baseUrl } from "../../../services/api";
import axios from "axios";


export const fetchUserData = createAsyncThunk('auth/fetchUserData', async(_, {rejectWithValue})=>{
    try {
        const accessToken = getToken()
        api.defaults.headers.Authorization = `Bearer ${accessToken}`
        const response = await axios.get('api/v1/authentication/user/profile')
        return { ...response.data, accessToken}
    } catch (error) {        
        removeToken()
        return rejectWithValue('')
    }
})

export const login = createAsyncThunk('auth/login', async(payload)=>{
    try {
        const response = await axios.post(`${baseUrl}api/v1/authentication/user/login`, payload)
        console.log(response.data)
        setToken(response.data.access_token)       
        setUser(response.data)
        return response.data
    } catch (error) {
        console.log('An error has occured',error.message)
        return error
    }    
})

export const register = createAsyncThunk('auth/register', async(payload)=>{
    try {
        const response = await axios.post(`${baseUrl}api/v1/authentication/user/register`, payload)
        return response.data
    } catch (error) {
        console.log('An error has occured',error.message)
        return error
    }
})

export const signOut = createAsyncThunk('auth/signOut', async(_, {rejectWithValue})=>{
    try {
        const response = await axios.get('api/v1/user/logout')
        removeToken()
        return response.data
    } catch (error) {
        return rejectWithValue('')
    }
})