import React, { useEffect, useState } from 'react'
import { Link, BrowserRouter as Router, Navigate, useNavigate} from 'react-router-dom';
import Root from './routes/Root';
import Footer from './pages/website/Layout/Footer';
import { getToken, getUser, removeToken, removeUser } from './utils/HelperFunction';
import Dashboard from './pages/dashboard/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { setAppState } from './store/features/app/appSlice';
import protectedRoute from './utils/protectedRoute';
import mqtt from 'mqtt';
import mqtt_conn from './utils/mqtt_conn';


const MQTT_BROKER_URL = 'ws:/4.156.26.184:8084/mqtt';

export default function App() {
  const [ token, setToken] = useState({token: getToken()})
  const [ user, setUser] = useState(getUser())
  const [message, setMessage] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([]);

  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const appState = useSelector(state => state.app.dash)

  function logOut(){      
    setToken(JSON.stringify(null))
    removeUser()
    removeToken();
    setUser(null);
    window.location.href = '/';
    // navigate('/', { refresh: true })
  }

  useEffect(()=>{
    // mqtt_conn()
    console.log('appState',protectedRoute())
  },[token,user])
  
  function Rooter(){
    return <Router>
      {/* <header class="text-slate-700 container relative mx-auto flex flex-col overflow-hidden px-4 py-4 lg:flex-row lg:items-center"> */}
      <div className='' style={{ backgroundColor: '#36b6ff' }} >
      <header className="text-white container relative mx-auto flex flex-col overflow-hidden px-4 py-4 lg:flex-row lg:items-center" style={{ backgroundColor: '#36b6ff' }} >
        <a href="/" class="flex items-center whitespace-nowrap text-2xl font-black">
           {/* <span class="mr-2 w-12"> */}
           <span class="mr-1">
            <img src="https://myridex.com/wp-content/themes/myridex-theme/assets/img/ridexLog1.png" alt="logo" style={{ width: '80px', height: 'auto' }} />
          </span>
          {/* spline */}
        </a>
        <input type="checkbox" class="peer hidden" id="navbar-open" />
        <label class="absolute top-5 right-5 cursor-pointer lg:hidden" htmlFor="navbar-open">
          <svg class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>
        <nav aria-label="Header Navigation" class="peer-checked:pt-8 peer-checked:max-h-60 flex max-h-0 w-full flex-col items-center overflow-hidden transition-all lg:ml-24 lg:max-h-full lg:flex-row">
          <ul class="flex w-full flex-col items-center space-y-2 lg:flex-row lg:justify-center lg:space-y-0 text-white">
            <li class="lg:mr-12">
              <Link to={'/'} class="rounded focus:outline-none focus:border-b focus:border-white focus:ring-offset-2">
                Home
              </Link>
            </li>
            <li class="lg:mr-12">
              <Link to={'services'} refresh="true" class="rounded focus:outline-none focus:border-b focus:border-white focus:ring-offset-2">
                Services
              </Link>
            </li>
            {
            token.token && user && (
              <li class="lg:mr-12">
                <Link to={'request'} refresh="true" class="rounded focus:outline-none focus:border-b focus:border-white focus:ring-offset-2">
                  Make Request
                </Link>
              </li>
            )
          }
            <li class="lg:mr-12">
              <Link to={'contact'} refresh="true" class="rounded focus:outline-none focus:border-b focus:border-white focus:ring-offset-2">
                Contact
              </Link>
            </li>
            {/* <li class="lg:mr-12"><a class="rounded text-gray-700 transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2" href="/">FAQ</a></li> */}
          </ul>
          <hr class="mt-4 w-full lg:hidden" />
          <div class="my-4 flex items-center space-x-6 space-y-2 lg:my-0 lg:ml-auto lg:space-x-8 lg:space-y-0">
            {
              user?.access_token && (
                <>
                  {user?.role_id === 1 ? (
                    <Link to={'account'} title="" className="whitespace-nowrap rounded font-medium transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 hover:text-opacity-50">
                      Account
                    </Link>
                  ) : user?.role_id === 3 ? (
                    <Link onClick={() => dispatch(setAppState())} title="" className="whitespace-nowrap rounded font-medium transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 hover:text-opacity-50">
                      Dashboard
                    </Link>
                  ):''
                // (<Link onClick={() => dispatch(setAppState())} title="" className="whitespace-nowrap rounded font-medium transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 hover:text-opacity-50">
                //   ProviderRequests
                // </Link>) 
                }
                  {user && (
                    <Link
                    to={'/'}
                    onClick={logOut}
                    title=""
                    className="whitespace-nowrap rounded-xl bg-blue-400 px-5 py-2 font-medium text-white transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 hover:bg-blue-400
                    lg:text-base lg:px-4 lg:py-2 lg:bg-blue-400 lg:hover:bg-blue-400"
                  >
                    Log Out
                  </Link>
                  )}
                </>
              )
            }
            {
              !user && (
                <>
                  <Link to={'auth'} title="" className="whitespace-nowrap rounded font-medium transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-offset-2 hover:text-opacity-50">
                    Sign In / Sign Up
                  </Link>
                </>
              )
            }
          </div>
        </nav>
      </header>
      </div>
      {/* {token.token &&(
        <>Hello </>
      )} */}
      <Root />
      <Footer />
    </Router>
  }

  return appState ? <Dashboard/> : <Rooter/>
}